<template>
  <main id="main">
    <div class="wrap1160">
      <section id="qa">
        <h1>
          <span>{{ $t('FAQ_TITLE_H1') }}</span>
        </h1>
        <h2><span>{{ $t('FAQ_TITLE_H2') }}</span></h2>
        <p class="repre">
          <span class="ttl">{{ $t('FAQ_MARKET_GUIDE') }}</span><br />
          <a @click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_PAGE_NAME)">{{ $t('FAQ_GUIDE_LINK') }}</a
          >{{ $t('FAQ_MARKET_GUIDE_TEXT') }}
        </p>
        <p class="repre">
          <span class="ttl">{{ $t('FAQ_BUYING_AND_SELLING') }}</span><br />
          {{ $t('FAQ_BUYING_AND_SELLING_TEXT') }}<a href="https://www.obm.jp/info.php?ID=4" target="_blank">必要書類について</a>
          {{ $t('FAQ_BUYING_AND_SELLING_TEXT__2') }}<br />
        </p>
        <p class="repre">
          {{ $t('FAQ_BUYING_AND_SELLING_TEXT__3') }}<br />
          <a href="https://www.obm.jp/info.php?ID=3" target="_blank">{{ $t('FAQ_MAINTENANCE_AND_REPAIR') }}</a
          >{{ $t('FAQ_BUYING_AND_SELLING_TEXT__4') }}
        </p>
        <h2><span>{{ $t('FAQ_TROUBLE') }}</span></h2>
        <div class="sttl">{{ $t('FAQ_SECTION1_TITLE') }}</div>
        <ul class="qa-wrapper">
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION1_Q1') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION1_A1') }}<span class="highlight"
                  >{{ $t('FAQ_SECTION1_A1_RED') }}</span
                >{{ $t('FAQ_SECTION1_A1__2') }}<br />
                {{ $t('FAQ_SECTION1_A1__3') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION1_Q2') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION1_A2') }}
                <figure>
                  <img src="@/assets/img/other/cap_reminder.png" />
                </figure>
              </dd>
            </dl>
          </li>
        </ul>
        <div class="sttl">{{ $t('FAQ_SECTION2_TITLE') }}</div>
        <ul class="qa-wrapper">
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION2_Q1') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION2_A1') }}<a @click="$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)">{{ $t('FAQ_SECTION2_A1_LINK') }}</a>{{ $t('FAQ_SECTION2_A1__2') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION2_Q2') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION2_A2') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION2_Q3') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION2_A3') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION2_Q4') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION2_A4') }}<br>
                {{ $t('FAQ_SECTION2_A4__2') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION2_Q5') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION2_A5') }}
              </dd>
            </dl>
          </li>
        </ul>
        <div class="sttl">{{ $t('FAQ_SECTION3_TITLE') }}</div>
        <ul class="qa-wrapper">
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION3_Q1') }}</dt>
              <dd><a href="https://www.obm.jp/info.php?ID=4" target="_blank">{{ $t('FAQ_REQUIRED_DOCUMENT') }}</a>{{ $t('FAQ_SECTION3_A1') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION3_Q2') }}</dt>
              <dd>{{ $t('FAQ_SECTION3_A2') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION3_Q3') }}</dt>
              <dd>{{ $t('FAQ_SECTION3_A3') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION3_Q4') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION3_A4') }}<br>
                {{ $t('FAQ_SECTION3_A4__2') }}<br>
                {{ $t('FAQ_SECTION3_A4__3') }}<br>
                {{ $t('FAQ_SECTION3_A4__4') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION3_Q5') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION3_A5') }}<br>
                {{ $t('FAQ_SECTION3_A5__2') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION3_Q6') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION3_A6') }}<br>
                {{ $t('FAQ_SECTION3_A6__2') }}<br>
                {{ $t('FAQ_SECTION3_A6__3') }}
              </dd>
            </dl>
          </li>
        </ul>
        <div class="sttl">{{ $t('FAQ_SECTION4_TITLE') }}</div>
        <ul class="qa-wrapper">
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION4_Q1') }}</dt>
              <dd>{{ $t('FAQ_SECTION4_A1') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION4_Q2') }}</dt>
              <dd>{{ $t('FAQ_SECTION4_A2') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION4_Q3') }}</dt>
              <dd>{{ $t('FAQ_SECTION4_A3') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION4_Q4') }}</dt>
              <dd>
                <span class="bold">{{ $t('FAQ_SECTION4_A4_SUBTITLE1') }}</span><br>
                {{ $t('FAQ_SECTION4_A4__2') }}<br>
                <span class="bold">{{ $t('FAQ_SECTION4_A4_SUBTITLE2') }}</span><br>
                {{ $t('FAQ_SECTION4_A4__3') }}
            </dd>
            </dl>
          </li>
        </ul>
        <div class="sttl">{{ $t('FAQ_SECTION5_TITLE') }}</div>
        <ul class="qa-wrapper">
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION5_Q1') }}</dt>
              <dd>{{ $t('FAQ_SECTION5_A1') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION5_Q2') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION5_A2') }}<br>
                {{ $t('FAQ_SECTION5_A2__2') }}<br>
                {{ $t('FAQ_SECTION5_A2__3') }}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{ $t('FAQ_DELIVERY') }}</a>{{ $t('FAQ_SECTION5_A2__4') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION5_Q3') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION5_A3') }}<br>
                {{ $t('FAQ_SECTION5_A3__2') }}<br>
                {{ $t('FAQ_SECTION5_A3__3') }}<br>
                {{ $t('FAQ_SECTION5_A3__4') }}
              </dd>
            </dl>
          </li>
        </ul>
        <div class="sttl">{{ $t('FAQ_SECTION6_TITLE') }}</div>
        <ul class="qa-wrapper">
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION6_Q1') }}</dt>
              <dd>{{ $t('FAQ_SECTION6_A1') }}<a @click="goToHashPage($define.PAGE_NAMES.OTHER_GUIDE_AUCTION_TERMS_PAGE_NAME, 'cancel')">{{ $t('FAQ_SECTION6_A1_LINK') }}</a>{{ $t('FAQ_SECTION6_A1__2') }}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION6_Q2') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION6_A2') }}<br>
                {{ $t('FAQ_SECTION6_A2__2') }}<br>
                {{ $t('FAQ_SECTION6_A2__3') }}<br>
                <span class="bold">{{ $t('FAQ_SECTION6_A2_SUBTITLE1') }}</span><br>
                {{ $t('FAQ_SECTION6_A2__4') }}<br>
                <span class="bold">{{ $t('FAQ_SECTION6_A2_SUBTITLE2') }}</span><br>
                {{ $t('FAQ_SECTION6_A2__5') }}<br>
                {{ $t('FAQ_SECTION6_A2__6') }}<br>
                {{ $t('FAQ_SECTION6_A2__7') }}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION6_Q3') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION6_A3') }}<br>
                {{ $t('FAQ_SECTION6_A3__2') }}
              </dd>
            </dl>
          </li>
        </ul>
        <div class="sttl">{{ $t('FAQ_SECTION7_TITLE') }}</div>
        <ul class="qa-wrapper">
          <li>
            <dl>
              <dt>{{ $t('FAQ_SECTION7_Q1') }}</dt>
              <dd>
                {{ $t('FAQ_SECTION7_A1') }}<br>
                {{ $t('FAQ_SECTION7_A1__2') }}<br>
                {{ $t('FAQ_SECTION7_A1__3') }}
              </dd>
            </dl>
          </li>
        </ul>
        <div class="info-box-support">
        <p>
          {{ $t('FAQ_SUPPORT_TEXT') }}<br>
          <a href="mailto:raima_support@obm.jp">raima_support@obm.jp</a>
          <br>
          {{ $t('FAQ_SUPPORT_TIME') }}
        </p>
        </div>
      </section>
    </div>
  </main>
</template>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="sass" src="@/assets/scss/guide.sass"></style>
<style scoped lang="sass" src="@/assets/scss/other.sass"></style>

<script>
export default {
  methods : {
    // #DOM先へ画面遷移
    goToHashPage(pageName, hashName) {
      const router = this.$router

      router.options.scrollBehavior = function(to, from, savedPosition) {
        if (to.hash) {
          return {selector : to.hash}
        } else {
          return {x : 0, y : 0}
        }
      }

      this.$router.push({
        name : pageName,
        hash : `#${hashName}`
      })
    }
  }
}
</script>
